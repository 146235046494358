// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"

interface OutOfCreditsDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const OutOfCreditsDialog = ({open, setOpen}:OutOfCreditsDialogProps) => {
    return (
        <Dialog open={open} onClose={()=> setOpen(false)}>
            <DialogTitle>
                <Typography variant="h3">Acreage Limit Reached!</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">These order changes cannot be finalized due to the account running out of credits. Please reach out to CS to add additional credits to their subscription.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> setOpen(false)} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    )
}