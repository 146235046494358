// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LayerListItem } from './LayerListItem';

interface LayerSelectProps {
  bundleType: string;
  onAddOnsClick: (item: IDeliverable, checked: boolean, typeFactor3D?: boolean) => void;
  selectAdditionalDeliverable: (data: IDeliverable, checked: boolean, typeFactor3D?:boolean) => void;
}

export const LayerSelect = ({ bundleType, onAddOnsClick, selectAdditionalDeliverable }: LayerSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { additionalDeliverables, selectedBundle, availableBundles } = useAppSelector(
    (state) => state.bundle,
  );

  const { name: bundleName, bundleId, type } = selectedBundle || {};
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { telecomLayerAttributions } = useFlags();
  const utilitiesBundle = bundleType === 'utilities';

  const ugAlaCarte = availableBundles.find((b) => b.name === 'Underground A-la-carte');

  const ugAlaCarteBoolean = bundleName === 'Underground A-la-carte';
  const utilitiesBoolean = additionalDeliverables.some((d) => d.subtype === 'Utilities');
  const roadsAndSidewalkBoolean = additionalDeliverables.some((d) => d.subtype === 'Road & Sidewalk');
  const vegetationAndLandscapeBoolean = additionalDeliverables.some((d) => d.subtype === 'Vegetation & Landscape');
  const topographyLayerBoolean = additionalDeliverables.some((d) => d.subtype === 'Topography' && d.type === 'layer');
  const administrativeBoolean = additionalDeliverables.some((d) => d.subtype === 'Administrative & Annotations');
  const planimetricsBoolean = additionalDeliverables.some((d) => d.subtype === 'Planimetrics');
  const measurementsBoolean = additionalDeliverables.some((d) => d.subtype === 'Measurements');
  const wallsAndFencesBoolean = additionalDeliverables.some((d) => d.subtype === 'Walls & Fences');
  const additionalLayersBoolean = additionalDeliverables.some((d) => d.subtype === 'Additional Layers');

  // Utilities Add-ons
  const ugBasicAddonBoolean = utilitiesBundle && additionalDeliverables.some((d) => d.subtype === 'Underground Basic Add-Ons');
  const ugHeavyAddonBoolean = utilitiesBundle && additionalDeliverables.some((d) => d.subtype === 'UG Heavy Add-on');
  const utilityPolesBasicBoolean = utilitiesBundle && additionalDeliverables.some((d) => d.subtype === 'Utility Poles - Basic');
  const heavyAddonAttributionsBoolean = utilitiesBundle && telecomLayerAttributions && additionalDeliverables.some((d) => d.subtype === 'UG Utility Heavy Add-on Attributions');
  const aerialAttributionsBoolean = utilitiesBundle && telecomLayerAttributions && additionalDeliverables.some((d) => d.subtype === 'Aerial 3D Add-on Attributions');
  const aerialMiscWiresBoolean = utilitiesBundle && additionalDeliverables.some((d) => d.subtype === 'Misc. Wires');
  const aerialBuiltInfrastructureBoolean = utilitiesBundle && additionalDeliverables.some((d) => d.subtype === 'Built Infrastructure');

  return (
    <Box sx={bundleName === 'Topo' && styles.unavailableDeliverables}>
      <Typography sx={styles.addonsHeading} variant="h3">
        Layers
      </Typography>
      {bundleName === 'Topo' ? (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add 2D features at checkout
        </Typography>
      ) : <Typography sx={styles.description}>Please select any additional layers you’d like to be drafted in your project.</Typography> }
      {(bundleId === 4 || bundleId === 5) && (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled layers at checkout
        </Typography>
      )}
      {ugBasicAddonBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Underground Basic Add-Ons
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Underground Basic Add-Ons' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {ugHeavyAddonBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            UG Heavy Add-on
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'UG Heavy Add-on' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {utilityPolesBasicBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utility Poles - Basic
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Utility Poles - Basic' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {heavyAddonAttributionsBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utility Heavy Layer Attributes
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'UG Utility Heavy Add-on Attributions' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={!item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {aerialMiscWiresBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Misc. Wires
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Misc. Wires' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={!item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {aerialBuiltInfrastructureBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Built Infrastructure
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Built Infrastructure' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={!item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {aerialAttributionsBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Aerial 3D Add-on Attributions
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Aerial 3D Add-on Attributions' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={!item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {utilitiesBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utilities
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Utilities' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={!item.selectable}
              />
            ) : null))}
        </Box>
      )}
      {roadsAndSidewalkBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Road & Sidewalk
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Road & Sidewalk' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      ) }
      {vegetationAndLandscapeBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Vegetation & Landscape
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Vegetation & Landscape' ? (
              <Box sx={(bundleName === '3D Feature Elevation' && !(item.deliverableId === 39)) && styles.unavailableDeliverables}>
                <LayerListItem
                  key={item._id}
                  item={item}
                  activeOrder={activeOrder}
                  ugAlaCarte={ugAlaCarte}
                  ugAlaCarteBoolean={ugAlaCarteBoolean}
                  selectAdditionalDeliverable={selectAdditionalDeliverable}
                  disabled={type !== 'utilities' && !item.selectable}
                />
              </Box>
            ) : null))}
        </Box>
      ) }
      {topographyLayerBoolean && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Topography
          </Typography>
          {bundleName === '2D' && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            ((item.type === 'layer' && item.subtype === 'Topography') ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      ) }
      { administrativeBoolean && (
        <Box sx={(bundleId === 0 || bundleId === 5) && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Administrative & Annotations
          </Typography>
          {(bundleId === 0 || bundleId === 5) && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Administrative & Annotations features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Administrative & Annotations' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      ) }
      { planimetricsBoolean && (
        <Box sx={bundleId === 4 && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Planimetrics
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Planimetrics' ? (
              <Box sx={(type !== 'utilities' && !item.selectable || (bundleName === '3D Feature Elevation' && !(item.deliverableId === 21))) && styles.unavailableDeliverables}>
                <LayerListItem
                  key={item._id}
                  item={item}
                  activeOrder={activeOrder}
                  ugAlaCarte={ugAlaCarte}
                  ugAlaCarteBoolean={ugAlaCarteBoolean}
                  selectAdditionalDeliverable={selectAdditionalDeliverable}
                  disabled={type !== 'utilities' && !item.selectable}
                />
              </Box>
            ) : null))}
        </Box>
      ) }
      { measurementsBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Measurements
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Measurements' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      ) }
      { wallsAndFencesBoolean && (
        <Box sx={bundleName === '3D Feature Elevation' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Walls & Fences
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Walls & Fences' ? (
              <LayerListItem
                key={item._id}
                item={item}
                activeOrder={activeOrder}
                ugAlaCarte={ugAlaCarte}
                ugAlaCarteBoolean={ugAlaCarteBoolean}
                selectAdditionalDeliverable={selectAdditionalDeliverable}
                disabled={type !== 'utilities' && !item.selectable}
              />
            ) : null))}
        </Box>
      ) }
      {additionalLayersBoolean && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Additional Layers
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Additional Layers' ? (
              <Box
                key={item.name}
                sx={(type !== 'utilities' && !item.selectable || (bundleId === 5 && item.deliverableId === 16)) && styles.unavailableDeliverables}
              >
                <LayerListItem
                  item={item}
                  activeOrder={activeOrder}
                  ugAlaCarte={ugAlaCarte}
                  ugAlaCarteBoolean={ugAlaCarteBoolean}
                  selectAdditionalDeliverable={selectAdditionalDeliverable}
                  disabled={type !== 'utilities' && !item.selectable}
                />
              </Box>
            ) : null))}
        </Box>
      ) }
    </Box>
  );
};

export default LayerSelect;
