// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  selected: {
    fontSize: '0.65rem',
    padding: '4px 8px',
    '&.Mui-selected': {
      backgroundColor: '#01A1DD',
      color: 'white',
    },
  },
  notSelected: {
    fontSize: '0.65rem',
    padding: '4px 8px',
  },
  right: {
    fontWeight: 400,
    fontSize: '13px',
    position: 'absolute',
    right: 0,
  },
  listItem: {
    padding: '0px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  checked: {
    '&.Mui-checked': {
      color: '#01A1DD',
    },
  },
  itemName: {
    fontWeight: 400,
    fontSize: '13px',
  },
});

export default styles;
