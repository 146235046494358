// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Box, Button, Checkbox, CircularProgress, Dialog, Fade, FormControlLabel, ListItem, Typography, useTheme } from "@mui/material";
import { GetDeliverablesThunk } from "Features/admin/adminThunk";
import { EditOrderThunk } from "Features/order/orderThunk";
import { useAppDispatch } from "Hooks";
import { useEffect, useState } from "react";
import { deliverableCost } from "Utils/deliverables";
import stylesDef from './styles';

interface EditAddonsDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId: string;
  projectId: string;
  orderAcreage: number;
  availableCredits: number;
  creditAcreage: number;
  setShowOutOfCreditsDialog?: (show: boolean) => void;
}

export const EditAddonsDialog = ({ open, setOpen, orderId, projectId, orderAcreage, availableCredits, creditAcreage, setShowOutOfCreditsDialog }: EditAddonsDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [deliverableTypes, setDeliverableTypes] = useState([]);
  const [availableDeliverables, setAvailableDeliverables] = useState([]);
  const [checkedDeliverables, setCheckedDeliverables] = useState([]);
  const [editCredits, setEditCredits] = useState(0)

  const handleCheckboxChange = (id: string) => {
    const creditCost = deliverableCost(availableDeliverables.find(item => item._id === id).acreFactor, orderAcreage);

    if (checkedDeliverables.includes(id)) {
      setCheckedDeliverables(checkedDeliverables.filter(item => item !== id));
      setEditCredits(editCredits - creditCost);
    } else {
      setCheckedDeliverables([...checkedDeliverables, id]);
      setEditCredits(editCredits + creditCost);
    }
  }

  const handleSaveChanges = async () => {
    const selectedDeliverables = checkedDeliverables.map(id => ({ id }));
    setIsLoading(true);
  
    const result = await dispatch(EditOrderThunk({ orderId, addOns: selectedDeliverables }));
    setIsLoading(false);
  
    if (result.success) {
      setOpen(false);
    } else if (result.message === 'Not enough credits available') {
      setOpen(false);
      setShowOutOfCreditsDialog(true);
    }
  };

  useEffect(() => {
    const getBundleDeliverables = async () => {
      const response = await dispatch(GetDeliverablesThunk(orderId, projectId));

      if (response) {
        const subtypesArray = [...new Set(response.map((item) => item.subtype))];
        const selectedDeliverables = response
          .filter((item) => item.selected === true)
          .map((item) => item._id);

        setDeliverableTypes(subtypesArray);
        setAvailableDeliverables(response);
        setCheckedDeliverables(selectedDeliverables);
      }
    };

    getBundleDeliverables();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition={false}
    >
      <Box sx={styles.dialogContainer}>
        <Typography variant="h3" sx={styles.dialogTitle}>Edit Add-ons and Deliverables</Typography>
        <Typography variant="subtitle1">Please select any additional layers you’d like to be drafted in your project.</Typography>
        <Box sx={styles.deliverablesList}>
          {
            deliverableTypes.map((type, index) => {
              return (
                <div key={index}>
                  <Typography variant="h4">
                    {type}
                  </Typography>
                  {availableDeliverables.map((deliverable: any) => {
                    if (deliverable.subtype === type) {
                      return (
                        <ListItem key={deliverable._id}>
                          <FormControlLabel
                            label={(
                              <div>
                                <Typography variant="body1" display="inline">{deliverable.name}</Typography>
                              </div>
                            )}
                            control={(
                              <Checkbox
                                disabled={!deliverable.selectable || !deliverable.acreFactor || deliverable.acreFactor === 0}
                                checked={checkedDeliverables.includes(deliverable._id)}
                                onChange={() => handleCheckboxChange(deliverable._id)}
                              />
                            )}
                          />
                          <Typography component="span">
                            {`  ${deliverableCost(deliverable.acreFactor, orderAcreage).toFixed(2)} credits`}
                          </Typography>
                        </ListItem>
                      )
                    }
                  })}
                </div>
              )
            })
          }
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button variant="text" sx={styles.closeButton} onClick={() => setOpen(false)}>Close</Button>
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            disabled={isLoading}
          >
            <Fade in={isLoading}>
              <CircularProgress size={24} sx={styles.buttonProgress}/>
            </Fade>
            <Fade in={!isLoading}>
              <span>Save Changes</span>
            </Fade>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
};