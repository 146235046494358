// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { ListItem, FormControlLabel, Typography, Box, Checkbox, ToggleButtonGroup, ToggleButton } from "@mui/material"
import { useTheme } from "@mui/system"
import stylesDef from "./styles"
import { useState } from "react";
import { useAppSelector } from "Hooks";

interface LayerListItemProps {
  item: any;
  activeOrder: any;
  ugAlaCarte: any;
  ugAlaCarteBoolean: boolean;
  selectAdditionalDeliverable: (data: IDeliverable, checked: boolean, typeFactor3D?:boolean) => void;
  disabled: boolean;
}

export const LayerListItem = ({item, activeOrder, ugAlaCarte, ugAlaCarteBoolean, selectAdditionalDeliverable, disabled}: LayerListItemProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  
  const { selectedAdditionals } = useAppSelector((state) => state.bundle);
  const [type, setType] = useState('2D');

  const handleCheck = (item:any, checked:boolean) => {
    if(type === '3D') {
      selectAdditionalDeliverable(item, checked, true)
    } else {
      selectAdditionalDeliverable(item, checked, false)
    }
  }

  const handleSwitchChange = (newType: string) => {
    const checked = selectedAdditionals.findIndex((i) => i._id === item._id) !== -1;

    if(newType === '3D') {
      setType('3D')
      if(checked){
        selectAdditionalDeliverable(item, false, false)  // we remove the 2D cost
        selectAdditionalDeliverable(item, checked, true)  // we add the 3D cost
      }
    } else {
      setType('2D');
      if(checked){
        selectAdditionalDeliverable(item, false, true)  // we remove the 3D cost
        selectAdditionalDeliverable(item, checked, false)  // we add the 2D cost
      }
    }
  }

  return (
    <ListItem sx={styles.listItem}>
      <FormControlLabel
        label={(
          <Box>
            <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
          </Box>
        )}
        control={(
          <Checkbox
            sx={styles.checked}
            disabled={disabled}
            checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
            onChange={(e) => handleCheck(item, e.target.checked)}
          />
        )}
      />
      {ugAlaCarteBoolean && item.typeFactor && item.availableBundles && item.availableBundles.includes(ugAlaCarte._id) ? (
      <>
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={(_event, value) => handleSwitchChange(value)}
        >
          <ToggleButton 
            value="2D" 
            sx={type === '2D' ? styles.selected : styles.notSelected}
            disabled={type === '2D'}
          >
            2D
          </ToggleButton>
          <ToggleButton 
            value="3D" 
            sx={type === '3D' ? styles.selected : styles.notSelected}
            disabled={type === '3D'}
          >
            3D
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography sx={styles.right} component="span">
          {`  ${(type === '2D' ? item.typeFactor[0] * activeOrder.acreage : item.typeFactor[1] * activeOrder.acreage).toFixed(2)} Credits`}
        </Typography>
      </>
      ) : (
        <Typography sx={styles.right} component="span">
          {`  ${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
        </Typography>
      )}
    </ListItem>
  )
}