// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { getJson, patchJson, postJson, deleteRequest } from 'Utils/http';
import { GetAdminProjectsRoute, GetAdminGeneralRoute, SIGN_UP_SUCCESS } from 'Utils/routes';
import history from 'Utils/history';
import moment from 'moment-timezone';
import { GetOrgStatsStartAction, GetOrgStatsSuccessAction, GetOrgUsersSuccessAction } from 'Features/account/accountActions';
import type { RootState } from 'Store';
import { SetSortFilterAction } from 'Features/projectList/projectListActions';
import { CreateOrgFailureAction, CreateOrgStartAction, CreateOrgSuccessAction, DeleteOrgAction, DeleteProjectAdminFailureAction,
  DeleteProjectAdminStartAction, DeleteProjectAdminSuccessAction, GetLastLoginFailureAction, GetLastLoginStartAction, GetLastLoginSuccessAction,
  GetLoginStatsFailureAction, GetLoginStatsStartAction, GetLoginStatsSuccessAction, GetOrgProjectsFailureAction, GetOrgProjectsStartAction,
  GetOrgProjectsSuccessAction, GetPricingAction, GetSubscriptionFailureAction, GetSubscriptionStartAction, GetSubscriptionSuccessAction,
  GetUsersStartAction, GetUsersSuccessAction, LoadListStartAction, LoadListSuccessAction, OrgEmailsFailureAction, OrgEmailsSuccessAction,
  SetLoadAllAction, SetLoadNewAction, SetOrgPricingFailureAction, SetOrgPricingStartAction, SetOrgPricingSuccessAction, SetRowsPerPageAction,
  SetSearchFilterAction, SetUserIdAction, UpdateCostPerAcreAction, UpdateOrgFailureAction, UpdateOrgSuccessAction, GetOrgAction, SetListAction,
  SetOrgIdAction, SetPageAction } from './adminActions';

moment.tz.setDefault('America/New_York');

export const GetOrgThunk =
  (orgId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/admins/getOrgDetails/${orgId}`;
    const { token } = getState().auth;

    const result = await getJson<IOrgResponse>(url, token);
    if (result.success) {
      dispatch(GetOrgAction(result.data.org));
    }

    return result;
  };

export const SetOrgIdThunk = (data: string) => (dispatch: Dispatch) =>
  dispatch(SetOrgIdAction(data));

export const SetSearchFilterThunk = (searchFilter: string) => (dispatch: Dispatch) => dispatch(SetSearchFilterAction(searchFilter));
export const SetSortFilterThunk = (sortFilter: string) => (dispatch: Dispatch) => dispatch(SetSortFilterAction(sortFilter));
export const SetPageThunk = (page: number) => (dispatch: Dispatch) => dispatch(SetPageAction(page));
export const SetRowsPerPageThunk = (rowsPerPage: number) => (dispatch: Dispatch) => dispatch(SetRowsPerPageAction(rowsPerPage));

export const SetListThunk = (newData: Array<IAdminUser>) => (dispatch: Dispatch) => dispatch(SetListAction(newData));

export const SetLoadAll = (newData: boolean) => (dispatch: Dispatch) => dispatch(SetLoadAllAction(newData));

export const SetLoadNew = (newData: boolean) => (dispatch: Dispatch) => dispatch(SetLoadNewAction(newData));

export const GetListThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { page, rowsPerPage, searchFilter, sortFilter, loadNew } = getState().admin;
  dispatch(LoadListStartAction());

  const url = `${API_URL}/admins/dashboard?limit=${rowsPerPage}&page=${page}&query=${searchFilter || ''}&sort=${sortFilter}`;
  const { token } = getState().auth;

  const result = await getJson<IOrgResponse>(url, token);
  if (!result.success) return;

  const data = {
    list: result.data.orgs,
    count: result.data.stats.totalOrgCount,
  };

  if (loadNew) {
    dispatch(SetListAction([]));
  }

  if (data.list.length === 0) {
    dispatch(SetLoadAllAction(true));
  } else {
    dispatch(LoadListSuccessAction(data));
  }

  dispatch(SetLoadNewAction(false));
};

export const GetOrgProjectsThunk = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/admins/getOrgProjects/${orgId}`;
  dispatch(GetOrgProjectsStartAction());
  const result = await getJson<IAdminProjectsResponse[]>(url, token);
  if (!result.success) {
    dispatch(GetOrgProjectsFailureAction());
  } else {
    dispatch(GetOrgProjectsSuccessAction(result.data.projects));
  }
};

export const DeleteProjectAdminThunk = (projectId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/projects/${projectId}`;

  dispatch(DeleteProjectAdminStartAction());
  const result = await deleteRequest(url, token);
  if (result.success) {
    history.push(GetAdminProjectsRoute(orgId));
    dispatch(DeleteProjectAdminSuccessAction(projectId));
  } else {
    dispatch(DeleteProjectAdminFailureAction());
  }
};

/**
 * Function to call the API to create a new organization
 * and dispatch the CreateOrgAsync action
 */
export const CreateOrgThunk = (data: { firstName: string, lastName: string, phoneNumber?: string, companyName: string, email: string, jobTitle?: string, captchaToken?: string, industry?: string }) => async (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(CreateOrgStartAction());
  const { firstName, lastName, email, companyName, phoneNumber, jobTitle, captchaToken, industry } = data;
  const url = `${API_URL}/users/createOrgAdmin`;
  const result = await postJson<IOrgResponse>(url, {
    firstName,
    lastName,
    email,
    companyName,
    captchaToken,
    ...(phoneNumber && { phoneNumber }),
    ...(jobTitle && { jobTitle }),
    ...(captchaToken && { captchaToken }),
    ...(industry && { industry }),
    contact_created_by: _ADMIN_ ? 'Created by AW Admin' : 'Created by Self Signup',
  });

  if (result.success) {
    dispatch(CreateOrgSuccessAction(result.data.organization));
    history.push(
      _ADMIN_
        ? GetAdminGeneralRoute(result.data.organization._id)
        : SIGN_UP_SUCCESS,
    );
  } else {
    dispatch(CreateOrgFailureAction(result.message));
  }
  return result;
};

export const GetOrgSubscriptionThunk = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/subscriptions/getOrgActiveSubscription/${orgId}`;
  const { token } = getState().auth;

  dispatch(GetSubscriptionStartAction());
  const result = await getJson<ISubscriptions>(url, token);

  if (result.success) {
    dispatch(GetSubscriptionSuccessAction(result.data));
  } else {
    dispatch(GetSubscriptionFailureAction(result.message));
  }
  return result;
};

export const UpdateOrgThunk = (data: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/updateOrgDetails/${data._id}`;
  const { token } = getState().auth;

  const result = await patchJson<IOrgResponse>(
    url,
    data,
    token,
  );
  if (result.success) {
    dispatch(UpdateOrgSuccessAction(result.data.org));
  } else {
    dispatch(UpdateOrgFailureAction(result.message));
  }

  return result;
};

/**
 * Function to call the API to get users in an organization with the orgId
 * and dispatch the GetUsersAsync action
 */
export const GetOrgUsersThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/admins/getOrgUsers/${orgId}`;

  dispatch(GetUsersStartAction());

  const result = await getJson<IUser[]>(url, token);

  if (!result.success) {
    return result;
  }

  if (result.success) {
    dispatch(GetUsersSuccessAction(result.data.data));
  }
  return result;
};

/**
 * Function to call the API to get user object for a particular userId
 * and dispatch the GetOrgUserAsync action
 */
export const GetOrgUserThunk = (userId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/users/${userId}`;
  const { token } = getState().auth;

  const result = await getJson<IUser>(url, token);
  if (result.success) {
    dispatch(GetOrgUsersSuccessAction(result.data));
  }

  return result;
};

export const SetUserIdThunk = (userId: string) => (dispatch: Dispatch) =>
  dispatch(SetUserIdAction(userId));

export const GetLastLoginThunk = (orgId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/organizations/getLastLogin/${orgId}`;
    const { token } = getState().auth;

    dispatch(GetLastLoginStartAction());
    const result = await getJson<{ lastLogin: string }>(url, token);
    if (result.success) {
      const timeString = result.data.lastLogin === 'N/A' ? result.data.lastLogin : moment(result.data.lastLogin).format('MM/DD/YYYY | hh:mm A');
      dispatch(GetLastLoginSuccessAction({ lastLogin: timeString }));
    } else {
      dispatch(GetLastLoginFailureAction(result.message));
    }

    return result;
  };

export const GetLoginStatsThunk = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/getLoginStats/${orgId}`;
  const { token } = getState().auth;

  dispatch(GetLoginStatsStartAction());
  const result = await getJson<{ thisMonth: thisMonthStats, perMonth: perMonthStats }>(url, token);
  if (result.success) {
    dispatch(GetLoginStatsSuccessAction(result.data));
  } else {
    dispatch(GetLoginStatsFailureAction(result.message));
  }

  return result;
};

export const GetOrgStatsThunk = (id: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/getOrgStats/${id}`;
  const { token } = getState().auth;

  dispatch(GetOrgStatsStartAction());

  const result = await getJson<IOrgStats>(url, token);
  if (result.success) {
    dispatch(GetOrgStatsSuccessAction(result.data));
  }

  return result;
};

export const UpdateCostPerAcreThunk = (costPerAcre: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/updateCostPerAcre`;
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const result = await patchJson<IOrgResponse>(
    url,
    {
      orgId,
      costPerAcre,
    },
    token,
  );
  if (result.success) {
    dispatch(UpdateCostPerAcreAction(result.data.org.costPerAcre));
  }

  return result;
};

export const DeleteOrgThunk = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/removeOrgPII`;
  const { token } = getState().auth;

  const data = { orgId };

  const result = await deleteRequest(url, token, data);

  if (result.success) {
    dispatch(DeleteOrgAction(orgId));
  }

  return result;
};

export const OrgEmailsThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/optOutOfEmails`;
  const { token } = getState().auth;

  const { orgId } = getState().admin;

  const data = { orgId };

  if (!orgId) {
    dispatch(OrgEmailsFailureAction('ordId undefined'));
    return;
  }

  const result = await patchJson<IOrgEmailsResponse>(url, data, token);

  if (result.success) {
    dispatch(OrgEmailsSuccessAction(result.data.optOutOfEmails));
  }
};

export const GetPricingThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/admins/pricing`;
  const { token } = getState().auth;

  const result = await getJson<{message: string, pricing: IPricingTier[]}>(url, token);
  if (result.success) {
    dispatch(GetPricingAction(result.data.pricing));
  }
};

export const AssignPricingPlanThunk = (pricingData: { startDate: string | Date, endDate?: string | Date, pricingPlanName: string, startingCredits?: number, creditsPurchased?: number, contractUrl?: string }) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/organizations/assignPricingPlan`;
  const { token } = getState().auth;
  const ownerOrganization = getState().admin.orgId;
  const data = { ...pricingData, ownerOrganization };

  dispatch(SetOrgPricingStartAction());
  const result = await postJson<{message: string, newOrganizationPlan: IPricingPlanInfo}>(url, data, token);
  if (result.success) {
    dispatch(SetOrgPricingSuccessAction(result.data.newOrganizationPlan));
    // we do this to pull express ai subscription if exists and set automate to null
    GetOrgSubscriptionThunk(ownerOrganization)(dispatch, getState);
    GetOrgThunk(ownerOrganization)(dispatch, getState);
  } else {
    dispatch(SetOrgPricingFailureAction());
  }
  return result.success;
};
export const GetOrgPastPlansThunk = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/admins/getOrgPastPlans/${orgId}`;
  const result = await getJson<{message: string, orgPastPlans: IPricingPlanInfo[]}>(url, token);

  if (result.success) {
    return result.data?.orgPastPlans;
  }

  return [];
};

/*
  * Function to get bundle deliverables for a project
*/
export const GetDeliverablesThunk = (orderId:string, projectId:string) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/deliverables/getBundleDeliverables`;
  const data = { orderId, projectId, orgId };

  const result = await postJson<{message: string, addOns: AddOns[]}>(url, data, token);

  if (result.success) {
    return result.data?.addOns;
  }

  return [];
};
